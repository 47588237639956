/* 2.3 Font weight*/
/* 2.1 Font Size*/
/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.text-bold {
  font-weight: bold;
}

.h1 {
  font-size: 24px;
}

.h2 {
  font-size: 20px;
}

.color-primary {
  color: #E36D17;
}
.color-gray1 {
  color: #B3B3B3;
}
.color-gray2 {
  color: #CCCCCC;
}
.color-gray3 {
  color: #E6E6E6;
}
.color-gray4 {
  color: #F4F4F4;
}

.pagination {
  justify-content: flex-end;
}

.page-link {
  color: #E36D17 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #E36D17 !important;
  border-color: #E36D17 !important;
}

.modalBody {
  padding: 1rem;
  text-align: center;
  font-size: 24px;
}
.modalBody-title {
  text-align: center;
  margin-bottom: 1rem;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  color: #4D4D4D;
  width: 12rem !important;
  text-align: center;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #E36D17 !important;
  background: white !important;
  font-weight: 600;
  border-bottom: 2px solid;
  width: 9rem;
  border-radius: 0;
  text-align: center;
}

.nav-link:hover {
  color: #4D4D4D !important;
  cursor: pointer;
}

.cpntr {
  cursor: pointer;
}

a {
  color: #E36D17 !important;
}

svg:not(:root) {
  overflow: unset;
}

html {
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  font-weight: 400;
  background: #f8f8f8;
  font-family: "Open Sans", sans-serif;
  font-family: "Poppins", sans-serif;
}

h1,
.h1,
h2,
.h2 {
  margin-top: 0;
  margin-bottom: 0.1rem;
  color: #333333;
  line-height: 150%;
  font-family: "Montserrat", sans-serif;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #333333;
}

p {
  margin-top: 0;
  line-height: 170%;
  font-size: 400;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  color: unset;
}
a:hover {
  color: #f7941d;
}

ul {
  list-style: none;
  padding: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.form-check-input {
  cursor: pointer;
}

thead {
  background: #3CC13B;
  color: white;
}

th {
  font-weight: 500;
  font-size: 18px;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
  font-size: 14px;
  color: #333333;
}

thead {
  border-style: hidden !important;
}

.strongUp {
  text-transform: uppercase;
  color: #f7941d;
}

.cpr {
  color: #E36D17;
}

.cprt {
  color: #F6F9FF;
}

.bpr {
  background: #E36D17;
}

.bprt {
  background: #F6F9FF;
}

.cse {
  color: #f7941d;
}

.bse {
  background: #f7941d;
}

.csu {
  color: #3CC13B;
}

.bsu {
  background: #3CC13B;
}

.csut {
  color: #D7F3D7;
}

.bsut {
  background-color: #D7F3D7;
}

.cwa {
  color: #F3BB1C;
}

.bwa {
  background: #F3BB1C;
}

.cwat {
  color: #FDF1D1;
}

.bwat {
  background: #FDF1D1;
}

.cda {
  color: #F03738;
}

.bda {
  background: #F03738;
}

.bdat {
  background: #FCD7D7;
}

.bcdat {
  background: #FCD7D7;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bton {
  font-weight: 400;
  letter-spacing: 0.5px;
  border-radius: 4px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
}
.bton--sm {
  padding-left: 20px;
  padding-right: 20px;
  line-height: 34px;
}
.bton--md {
  padding-left: 28px;
  padding-right: 28px;
  line-height: 39px;
}
.bton--lg {
  padding-left: 36px;
  padding-right: 36px;
  line-height: 39px;
}
.bton--full {
  line-height: 48px;
  width: 100%;
}
.bton--half {
  line-height: 48px;
  width: 50%;
}
.bton--primary {
  border-color: #E36D17;
  color: white;
  background-color: #E36D17;
}
.bton--primary:hover {
  background-color: #183b77;
  border-color: #183b77;
  color: white;
}
.bton--secondary {
  border-color: #f7941d;
  color: white;
  background-color: #f7941d;
}
.bton--secondary:hover {
  background-color: #ffae4e;
  border-color: #ffae4e;
  color: white;
}
.bton--secondary:active {
  border-color: #e78a34;
}
.bton--ghost {
  border-radius: 4px;
  border-color: #E36D17;
  color: #E36D17;
  background-color: #FFFFFF;
}
.bton--ghost:hover {
  background-color: #183b77;
  border-color: #183b77;
  color: white;
}
.bton--ghost--danger {
  border-color: #F03738;
  background: #FCD7D7;
  color: #F03738;
}
.bton--ghost--danger:hover {
  background-color: #F03738;
  border-color: #F03738;
  color: white;
}
.bton--secondary {
  border-color: white;
  color: white;
  background-color: transparent;
}
.bton--secondary:hover {
  background: white;
  color: #f7941d;
}
.bton--danger {
  background: #F03738;
  color: white;
  border: #F03738;
}
.bton--nacked {
  border: none;
  color: rgb(20, 20, 185);
  background: none;
  padding: 0;
}

.searchComp-input {
  height: 42px;
  margin-right: 5px;
  width: 25rem;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  padding: 1rem;
}

.dateComp .dateInput {
  height: 42px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  padding: 1rem;
  color: #4D4D4D;
}

.checkBox {
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  padding: 0.3rem 1rem;
  color: #4D4D4D;
  display: inline-block;
}

.navbar-nav .nav-link {
  font-family: "Roboto", "sans-serif" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #0d0d0d !important;
  margin-right: 40px;
}

.navbar {
  position: sticky !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background: #fff;
  box-shadow: -1px -1px 10px 0px rgba(0, 0, 0, 0.75);
}

.footer {
  background-color: #282828;
  padding: 35px 0 15px 0;
  color: #fff;
}
.footer p {
  margin-top: 28px;
  font-size: 18px;
  color: #E36D17;
  font-weight: 600;
}
.footer__desc {
  margin-top: 0px !important;
  color: #fff !important;
  font-weight: 300 !important;
  font: "Roboto", "sans-serif" !important;
}
.footer ul {
  padding-left: 0;
  margin-top: 28px;
}
.footer ul li {
  padding-bottom: 12px;
}
.footer ul li a {
  color: #fff !important;
  font-weight: 300 !important;
}
.footer img {
  margin-bottom: 28px;
}
.footer__icon {
  font-size: 28px;
  margin-right: 15px;
}
.footer__secondheading {
  margin-left: 40px !important;
}
@media all and (max-width: 768px) {
  .footer__secondheading {
    margin-left: 0 !important;
  }
}
.footer__listing {
  margin-left: 40px !important;
}
@media all and (max-width: 768px) {
  .footer__listing {
    margin-left: 0 !important;
  }
}

.home__banner img {
  width: 100%;
  position: relative;
}
.home__bannerContent {
  position: absolute;
  top: 32%;
  overflow-x: hidden;
}
@media all and (max-width: 768px) {
  .home__bannerContent {
    top: 45%;
  }
}
@media all and (max-width: 576px) {
  .home__bannerContent {
    top: 35%;
  }
}
@media all and (max-width: 480px) {
  .home__bannerContent {
    top: 30%;
  }
}
@media all and (max-width: 480px) {
  .home__bannerContent {
    top: 18%;
  }
}
.home__bannerContent--heading {
  font-family: "Montserrat", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
}
@media all and (max-width: 768px) {
  .home__bannerContent--heading {
    font-size: 25px;
    line-height: 30px;
  }
}
@media all and (max-width: 768px) {
  .home__bannerContent--heading {
    font-size: 15px;
    line-height: 20px;
    width: 55%;
  }
}
.home__bannerContent--paragraph {
  font-family: "Roboto", "sans-serif";
  font-style: normal;
  font-weight: 454;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-top: 24px;
  width: 95%;
}
@media all and (max-width: 768px) {
  .home__bannerContent--paragraph {
    margin-top: 10px;
    font-size: 15px;
    line-height: 18px;
  }
}
@media all and (max-width: 576px) {
  .home__bannerContent--paragraph {
    display: none;
  }
}
.home__bannerContent a {
  font-family: "Roboto", "sans-serif";
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  background-color: #282828;
  color: #ffffff !important;
  margin-top: 44px;
  padding: 11px 34px;
  display: inline-block;
}
@media all and (max-width: 768px) {
  .home__bannerContent a {
    margin-top: 10px;
    font-size: 15px;
    padding: 8px 25px;
  }
}
@media all and (max-width: 576px) {
  .home__bannerContent a {
    margin-top: 5px;
    font-size: 14px;
    padding: 5px 15px;
  }
}
.home__productheading {
  font-family: "Montserrat", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 54px;
  letter-spacing: 0.02em;
  position: relative;
  color: #E36D17;
  margin-top: 60px;
}
.home__productheading::before {
  content: "";
  position: absolute;
  top: 45px;
  height: 4px;
  width: 60px;
  background-color: #e36d17;
}
.home__products {
  margin-top: 44px;
}
@media all and (max-width: 768px) {
  .home__products .abcd img {
    width: 250px;
    height: 300px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
.home__products--id {
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.15);
  margin-bottom: 0;
}
.home__products--name {
  font-family: "Montserrat", "sans-serif";
  font-weight: 700;
  font-size: 26px;
  line-height: 54px;
  letter-spacing: 0.02em;
  color: #282828;
  margin: 0;
}
.home__products--desc {
  font-family: "Roboto", "sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #282828;
}
.home__aboutus {
  margin-top: 60px;
  background-color: #fdf3ed;
  padding: 60px 0;
}
.home__aboutus video {
  width: 100%;
  height: 550px;
}
@media all and (max-width: 768px) {
  .home__aboutus video {
    height: 280px;
  }
}
.home__aboutus--heading {
  font-family: "Montserrat", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 54px;
  letter-spacing: 0.02em;
  position: relative;
  color: #E36D17;
  margin-bottom: 44px;
}
.home__aboutus--heading::before {
  content: "";
  position: absolute;
  top: 45px;
  height: 4px;
  width: 60px;
  background-color: #e36d17;
}
.home__testimonials {
  margin: 60px 0;
  font-family: "Montserrat", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 54px;
  letter-spacing: 0.02em;
  position: relative;
  color: #E36D17;
}
.home__testimonials .col-md-6 {
  margin: 0 auto;
  width: 97% !important;
}
.home__testimonials::before {
  content: "";
  position: absolute;
  top: 45px;
  height: 4px;
  width: 60px;
  background-color: #e36d17;
}
.home__testimonials--content {
  background-color: #f3f3f3;
  color: #000;
  padding: 30px;
  position: relative;
  margin-top: 40px;
}
.home__testimonials--content-quote {
  position: absolute;
  color: #d0d0d0;
}
.home__testimonials--content-para {
  font-family: "Roboto", "sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 40px;
}
.home__testimonials--content-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #282828;
  margin-bottom: 0;
}
.home__testimonials--content-post {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #282828;
}
.home__contactus {
  background-color: #fdf3ed;
  padding: 60px 0;
}
.home__contactus iframe {
  height: 500px;
  width: 100%;
}
@media all and (max-width: 768px) {
  .home__contactus iframe {
    margin-top: 20px;
  }
}
.home__contactus button {
  background-color: #e36d17;
  border: none;
  border-radius: 0;
  width: 100%;
  padding: 16px;
}
.home__contactus button:hover {
  background-color: #e36d17;
}
.home__contactus--heading {
  margin-bottom: 40px;
  font-family: "Montserrat", "sans-serif";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 54px;
  letter-spacing: 0.02em;
  position: relative;
  color: #E36D17;
}
.home__contactus--heading::before {
  content: "";
  position: absolute;
  top: 45px;
  height: 4px;
  width: 60px;
  background-color: #e36d17;
}

.nthselector:nth-child(even) .abcd {
  order: 2;
}

.otherContactWrapper {
  margin: 5rem 0;
}
.otherContactWrapper .BranchContact {
  height: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 1px 2px 10px rgba(227, 108, 23, 0.4117647059);
  border-radius: 6px;
}
.otherContactWrapper .BranchContact-location {
  font-size: 19px;
  color: #E36D17;
}
.otherContactWrapper .BranchContact-name {
  font-size: 17px;
  margin-left: 2rem;
  margin-top: 0.5rem;
  font-weight: 400;
  color: #505050;
}
.otherContactWrapper .BranchContact-name__post {
  color: #8d8d8d;
  font-weight: 300;
}
.otherContactWrapper .BranchContact-Contact {
  font-size: 16px;
  margin-left: 2rem;
  margin-top: 0.5rem;
  font-weight: 400;
  color: #505050;
}/*# sourceMappingURL=App.css.map */